import { useTenantId } from '@/store/store';

// both for prod and staging
// Only URLs will be different
//   staging: https://ascenda-demo.us.kaligo-staging.xyz/
//   prod: https://ascenda-demo.us.ascenda.com
const tenantHubIds = new Set(['ascenda-demoRcUs']);

// This should be similar to the key from GH's TenantHub side
export const TENANT_HUB_MESSAGE_TYPE = 'tenant_hub_custom_ui_configs';
export interface TenantHubConfig {
  header_color?: string;
  header_font_color?: string;
  footer_color?: string;
  footer_font_color?: string;
  favicon_url?: string;
  logo_url?: string;
  primary_color?: string;
  company_name?: string;
}

export function isTenantHubId(tenantId: string) {
  return tenantHubIds.has(tenantId);
}

export function useIsTenantHub() {
  const tenantId = useTenantId();
  return tenantId ? isTenantHubId(tenantId) : false;
}
